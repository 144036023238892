
import "./blog-post.css"

const Postbanner = ({image})=>{

    return(
        <div className = "postbanner text-center">
           <img src = {image} alt = "banner-post"/>
        </div>
    );
}

export default Postbanner;