import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, useParams } from 'react-router-dom'
import './demo-banner.css'

const Demobanner = () => {
  return (
    <section className='middle-banner text-center'>
      <Container>
        <Row>
          <Col xs={12}>
            <h1>
            Monitor Commodity Producers & Traders in Real-Time <br/> with Maritime & Space Tech
            </h1>
            <Link to='/request-demo' className='btn-main'>
              Request Demo
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Demobanner
