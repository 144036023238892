
import Col from "react-bootstrap/Col";

const TermsBanner = ({title})=> {
    return (
       
        <Col sm = {12} className = "text-center">
            <h1>{title}</h1>
        </Col>
    )
}

export default TermsBanner;