import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import { useParams } from 'react-router'
const Integrations = ({ image, header, pra, id1 }) => {
  return (
    <Row className='align-items-center mb-3 mb-lg-5 mx-0' id={id1}>
      <Col md={{ span: 4, offset: 1 }}>
        <div className='img-sec-side'>
          <img src={image} alt='integration-img' />
        </div>
      </Col>
      <Col md={{ span: 6, offset: 1 }}>
        <div className='text-sec-side text-start'>
          <h3>{header}</h3>
          <p>{pra}</p>
        </div>
      </Col>
    </Row>
  )
}

export default Integrations
