import axios from 'axios'

export const TIMEOUT = 15000
console.log(process.env.REACT_APP_AUTH);
const apiUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL: apiUrl,
  // baseURL: `http://127.0.0.1:3333/api/v1`,
  timeout: TIMEOUT
})
instance.interceptors.response.use(
  response => response,
  error => {
    return error
  }
)
export default instance
