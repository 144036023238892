import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useEffect, useLayoutEffect } from 'react'
import './request-demo.css'

const Requestdemo = () => {
  useLayoutEffect(() => {
    setInterval(() => {
      setClockTime()
    }, 500)
  }, [])

  useEffect(() => {
    setInterval(() => {
      setClockTime()
    }, 1000)
  }, [])

  const setClockTime = () => {
    // const secondHand = document.querySelector('.seconds-container')
    const minsHand = document.querySelector('.minutes-container')
    const hourHand = document.querySelector('.hours-container')

    const localDate = new Date()

    // Get the UTC offset of the user's local time zone in minutes
    const offsetMinutes = localDate.getTimezoneOffset()

    // Convert the offset to milliseconds and add to the current time
    const now = new Date()

    const mins = now.getMinutes()
    const hour = now.getHours()

    const minsDegrees = mins * 6
    const hourDegrees = 30 * hour + mins / 2

    minsHand.style.transform = `rotate(${minsDegrees}deg)`
    // const hourDegrees = (hour % 12) * 30
    hourHand.style.transform = `rotate(${hourDegrees}deg)`
  }
  return (
    <section className='request-demo'>
      <Container>
        <Row>
          <Col sm={12}>
            <h1>
              Inaction is a Strategic Decision. Can You Afford to be Left
              Behind? The Clock is Ticking.
            </h1>
            <p>
              It costs nothing to explore your options. Come find out what your
              competitors, clients & suppliers already know.
            </p>

            <Row>
              <Col sm={12}>
                <section className='demo-clock'>
                  <div className='clock'>
                    <div className='hours-container'>
                      <div className='hours'></div>
                    </div>
                    <div className='minutes-container'>
                      <div className='minutes'></div>
                    </div>
                    {/* <div className='seconds-container'>
                      <div className='seconds'></div>
                    </div> */}
                    <div className='demo-frame'>
                      <ul className='nav flex-column'>
                        <li className='nav-item'>
                          <a
                            href='#'
                            onClick={e => {
                              e?.preventDefault()
                              window?.Calendly?.initPopupWidget({
                                url: 'https://calendly.com/navigate-commodities/15min'
                              })
                            }}
                          >
                            15 Minutes Meeting
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            href='#'
                            onClick={e => {
                              e?.preventDefault()
                              window?.Calendly?.initPopupWidget({
                                url: 'https://calendly.com/navigate-commodities/30min'
                              })
                            }}
                          >
                            30 Minutes Meeting
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Requestdemo
