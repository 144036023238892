import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';

import './centertopbanner.css';


    const Topbanner= ({header,pradetails})=>{
    return  (
            <Row className= "align-items-center">
                <Col md = {12}>
                    <h1>
                        {header}
                    </h1>
                    <p className="banner-pra">
                        {pradetails}
                    </p>
                    <Row className = "align-items-center d-none d-md-flex">
                        <Col xs = "12">
                            <Link to = "/request-demo" className="btn-main">
                                Request Demo
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

export default Topbanner