import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Link } from 'react-router-dom'

const Mediapost = ({ header, title, pdate, image, link ,video_url}) => {
  return (
    <Row className='media-post-row'>
      <Col sm={12} className='media-col'>
        <Row>
          <Col lg={8} className='mb-3 mb-lg-0 order-last order-md-first'>
            {
              video_url!==null ?
              <a href={video_url} target="_blank" style={{textDecoration:'none'}}>
                   <h3>{header}</h3>
              </a>
               :  <Link to={link} style={{textDecoration:'none'}}> <h3>{header}</h3></Link>
              }
        {
              video_url!==null ?
              <a href={video_url} target="_blank" style={{textDecoration:'none'}}>
                  <p>{title}</p>
              </a>
              :
              <Link to={link} style={{textDecoration:'none'}}><p>{title}</p></Link>
        }
           
            <div className='post-date'>{pdate}</div>
            {
              video_url!==null ?
              <a href={video_url} target="_blank" className='btn-main'>
                  View Now
              </a>
              :
              <Link to={link} className='btn-main'>
              View Now
             </Link>
            }
            
          </Col>
          <Col lg={4} className='order-first order-md-last mb-3 mb-md-0'>
          {
              video_url!==null ?
              <a href={video_url} target="_blank" >
                  <div className='media-img'>
                <img src={image} alt='media' />
              </div>
              </a>
              :
              <Link to={link} >
              <div className='media-img'>
                <img src={image} alt='media' />
              </div>
              </Link>
              }
        
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Mediapost
