import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./about.css";

import companyImg from "../../images/about/our_company-ic.svg";
import SEAISI from "../../images/about/Logos/GreySEAISI.png";
import SFA from "../../images/about/Logos/GreySFA.png";
import SSTL from "../../images/about/Logos/GreySSTL.png";
import SEAISIcolor from "../../images/about/Logos/ColorSEAISI.png";
import SFAcolor from "../../images/about/Logos/ColorSFA.png";
import SSTLcolor from "../../images/about/Logos/ColorSSTL.png";
import MarineTraffic from "../../images/about/Logos/GreyMarineTraffic.png";
import Datalastic from "../../images/about/Logos/GreyDatalasticlogo.png";
import GreyGTT from "../../images/about/Logos/GreyGTT.png";
import Fubao from "../../images/about/Logos/GreyFubao.png";
import Bloomberg from "../../images/about/Logos/GreyBloomberg.png";
import WorldSteel from "../../images/about/Logos/GreyWorldSteel.png";
import GreyWind from "../../images/about/Logos/GreyWind.png";
import MarineTrafficColor from "../../images/about/Logos/ColorMarineTraffic.png";
import DatalasticColor from "../../images/about/Logos/ColorDatalastic.png";
import GreyGTTColor from "../../images/about/Logos/ColorGTT.png";
import FubaoColor from "../../images/about/Logos/ColorFubao.png";
import BloombergColor from "../../images/about/Logos/ColorBloomberg.png";
import WorldSteelColor from "../../images/about/Logos/ColorWorldSteel.png";
import GreyWindColor from "../../images/about/Logos/Colorwind.png";
import StartupSG from "../../images/about/Logos/GreyStartupSG.png";
import EnergyRisk from "../../images/about/Logos/GreyEnergyRisk.png";
import StartupSGColor from "../../images/about/Logos/ColourStartUpSG.png";
import EnergyRiskColor from "../../images/about/Logos/ColorEnergyRisk.png";
import EarthColor from "../../images/about/Logos/Colour-Earth-i.png";
import EarthGrey from "../../images/about/Logos/Grey-Earth-i.png";
import EnergyRiskGrey23 from "../../images/about/Logos/Greyenergy23.png";
import EnergyRiskColor23 from "../../images/about/Logos/Colorenergy23.png";

import { useParams } from "react-router-dom";
import React from "react";
const Ourcompany = () => {
  const { id } = useParams();
  React.useEffect(() => {
    console.log(id);
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);
  return (
    <section className="our-company" id="company">
      <Container>
        <h2 className="text-center">Our Company</h2>
        <div className="company position-relative">
          <Row>
            <Col sm={12} className="text-center">
              <img className="company-world" src={companyImg} alt="company" />
            </Col>
          </Row>
          <div className="partner-members">
            <h3>Associations & Membership</h3>
            <Row>
              <Col sm={12}>
                <ul className="nav align-item-center justify-content-center">
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={SEAISI}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={SEAISIcolor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={SFA}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={SFAcolor}
                        alt="association-img"
                      />
                    </div>
                  </li>

                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={SSTL}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={SSTLcolor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className="partner-members">
            <h3>Partners</h3>
            <Row>
              <Col sm={12} className="mb-3">
                <ul className="nav align-item-center justify-content-center partner-logo">
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={MarineTraffic}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={MarineTrafficColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={Datalastic}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={DatalasticColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={GreyGTT}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={GreyGTTColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  {/* <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={EarthGrey}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={EarthColor}
                        alt="association-img"
                      />
                    </div>
                  </li> */}
                </ul>
              </Col>
              <Col sm={12}>
                <ul className="nav align-item-center justify-content-center partner-logo">
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={Bloomberg}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={BloombergColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={WorldSteel}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={WorldSteelColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={GreyWind}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={GreyWindColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        className="grey-img"
                        src={Fubao}
                        alt="association-img"
                      />
                      <img
                        className="color-img"
                        src={FubaoColor}
                        alt="association-img"
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <Container>
        <section className="our-achivement" id="our-achievements">
          <h2 className="text-center">Our Achievements</h2>
          <div className="company achiements position-relative">
            <div className="partner-members">
              <Row className="align-items-center">
                <Col sm={12}>
                  <ul className="nav align-item-center justify-content-center">
                    <li className="nav-item">
                      <div>
                        <img
                          className="grey-img"
                          src={StartupSG}
                          alt="association-img"
                        />
                        <img
                          className="color-img"
                          src={StartupSGColor}
                          alt="association-img"
                        />
                      </div>
                    </li>
                    <li className="nav-item">
                      <div>
                        <img
                          className="grey-img"
                          src={EnergyRisk}
                          alt="association-img"
                        />
                        <img
                          className="color-img"
                          src={EnergyRiskColor}
                          alt="association-img"
                        />
                      </div>
                    </li>
                    <li className="nav-item">
                      <div>
                        <img
                          className="grey-img"
                          src={EnergyRiskGrey23}
                          alt="association-img"
                        />
                        <img
                          className="color-img"
                          src={EnergyRiskColor23}
                          alt="association-img"
                        />
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default Ourcompany;
