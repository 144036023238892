
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './solution-sec.css';


    const Solutions= ()=>{
    return  (
        <section className="solution mt-lg-5 py-3 py-lg-4 text-center">
            <Container>
                <Row>
                    <Col xs = {12}>
                        <h2>
                        Greater Transparency creates Greater Opportunities
                        </h2>
                        <p className="banner-pra">
                        Navigate Commodities monitors the movement & consumption of dry bulk commodities over land & sea using the most powerful technologies available.
                        <br/>
                        <br/>
                        We help commodity professionals identify real-world opportunities by revealing company-specific real-time trade and smelting activity.
                        </p>
                    </Col>
                </Row>
                <Row className="py-3 py-lg-4">
                    <Col xs = {12}>
                        <Link to = "/methodology" state={{ fromlink: "viewsolution" }} className="btn-main">
                            View Solutions
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
        );
    }

export default Solutions