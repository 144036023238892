import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import Centertopbanner from '../components/center-top-banner/centertopbanner'
import DataCard from '../components/technology/data-card'
import OurdataCard from '../components/technology/our-data'
import Demobanner from '../components/demobanner/Demobanner'

import Coalgraphic from '../images/data-coverage/coal-graphic.svg'
import Ferrographic from '../images/data-coverage/ferro-graphic.svg'
import Cementgraphic from '../images/data-coverage/cement-graphic.svg'
import Dockship from '../images/data-coverage/DockedShip.svg'
import BFplant from '../images/data-coverage/BF-Plant.svg'
import MachineLearning from '../images/data-coverage/FeStockpile.svg'
import Datapoint from '../images/data-coverage/data-point.svg'
import Scrappile from '../images/data-coverage/ScrapPile.svg'
import Steeltruck from '../images/data-coverage/SteelTruck.svg'
import Miningtruck from '../images/methodology/MiningTruck.svg'
import Globe from '../images/data-coverage/Globe.svg'
import Topsection_mob from '../images/data-coverage/mobile-top.svg'
import Aus_Graph_img from '../images/data-coverage/Australia-Iron-Ore-Exports-C.png'
import Turkey_Graph_img from '../images/data-coverage/Turkiye-Ferrous-Scrap-Imports-C.png'

import { useEffect } from 'react'


const DataCoverage = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})
  }, [])
  return (
    <section className='main'>
      <section className='main-banner inner-banner center-top-banner pb-3 pb-md-5'>
        <Container className='text-center'>
          <Centertopbanner
            header={'Unparalleled Dry Bulk Commodity Sector Coverage'}
            pradetails={'Almost Complete End-to-End Visibility'}
          />
        </Container>
      </section>

      <section className='data-section top-area pt-0 d-none d-md-block'>
        <Container>
          <Row>
            <Col md={3} lg={3}>
              <div className='sidecard top-left'>
                <DataCard image={Scrappile} title={'Ferrous Scrap'} />
              </div>
              <div className='sidecard bottom-left'>
                <DataCard image={Steeltruck} title={'Steel'} />
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className='centeral-globe text-center align-items-center h-100 d-flex'>
                <img src={Globe} alt='globe-graphic' />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div className='sidecard top-right'>
                <DataCard image={MachineLearning} title={'Iron Ore'} />
              </div>
              <div className='sidecard bottom-right'>
                <DataCard image={Miningtruck} title={'Coking Coal'} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='data-section top-area pt-0 d-md-none'>
        <Container>
          <Row>
            <Col sm={12}>
              <img
                className='w-100'
                src={Topsection_mob}
                alt='mobile-top-section'
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className='data-section'>
        <Container>
          <h2>Coming Soon</h2>
          <Row>
            <Col md={4} className='mb-3 mb-md-0'>
              <DataCard image={Coalgraphic} title={'Coal'} />
            </Col>
            <Col md={4} className='mb-3 mb-md-0'>
              <DataCard image={Ferrographic} title={'Ferro Alloys'} />
            </Col>
            <Col md={4} className='mb-3 mb-md-0'>
              <DataCard image={Cementgraphic} title={'Cement'} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className='data-section our-data'>
        <Container>
          <h2>Our Data</h2>
          <Row>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              {/* <span>18,836,</span> */}

              
              <OurdataCard
                end={1883664600}
                title={'Data Points Processed'}
                image={Datapoint}
                start={0}
                duration={8}
              />
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <OurdataCard
                 end={194}
                title={'Dry Bulk Ports Tracked'}
                image={Dockship}
                start={0}
                duration={8}
              />
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <OurdataCard
                end={137}
                title={'Buyers/Sellers Monitored'}
                image={BFplant}
                start={0}
                duration={8}
              />
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              
              <OurdataCard
               end={2239476155}
                title={'Tonnes of Dry Bulks Checked'}
                image={MachineLearning}
                start={0}
                duration={8}

              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className='data-section accuracy'>
        <Container>
          <h2>Accuracy</h2>
          <Tab.Container id='graph-tab' defaultActiveKey='first'>
            <Row>
              <Col md={5} lg={4} className='mb-3 mb-md-0'>
                <Nav className='flex-column'>
                  <Nav.Item>
                    <Nav.Link eventKey='first'>
                    Australia Iron Ore Exports
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='second'>Türkiye Ferrous Scrap Imports</Nav.Link>
                  </Nav.Item>
                 
                </Nav>
              </Col>
              <Col md={7} lg={8}>
                <Tab.Content>
                  <Tab.Pane eventKey='first'>
                    <img src={Aus_Graph_img} alt='graph' />
                  </Tab.Pane>
                  <Tab.Pane eventKey='second'>
                    <img src={Turkey_Graph_img} alt='graph' />
                  </Tab.Pane>

                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default DataCoverage
