// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import SupplyDemand from '../images/product/Directory-1.svg'
import SatelliteData from '../images/product/Directory-2.svg'
import Miningdata from '../images/product/Directory-3.svg'
import interactive_Directory from '../images/inner-banner/interactiveDirectory.svg'
import React, { useEffect } from 'react'
const InteractiveDirectory = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})
  }, [])
  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'Interactive Directory'}
            pradetails={
             <p className="banner-pra">The World's most extensive interactive directory of Ferrous & <br></br> Non-Ferrous Scrap merchants & bulk exporters in North America, UK, Scandinavia, Baltic, and Northern & Eastern European.</p> 
            }
            image={
              <img
                className='innerbanner-img navigate_ineractive'
                src={interactive_Directory}
                alt='banner-graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data & Features'}
          Pradetails={
            <p className="banner-pra">A completely immersive & interactive directory of Ferrous & Non-Ferrous Scrap suppliers <br/> that can be quickly filtered & sliced by multiple criteria per country</p>
          }
        />
      </section>

      <section className='middle-top interactive_directory py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={SupplyDemand}
            header={'Extensive Coverage'}
            pra={
              'Through exhaustive primary research, we provide detailed information on more than 800 Ferrous & Non-Ferrous recycling facilities across much of the Northern Hemisphere.'
            }
          />
          <Services
            image={SatelliteData}
            header={'Company Information'}
            pra={
              'Granular site-specific information includes metals processed, processing equipment, group capacity, average shipment size, port & berth location, cargo types, key personnel, and contact details.'
            }
          />
          <Services
            image={Miningdata}
            header={'On-Demand Insights'}
            pra={
              'Bespoke interactive data visualizations & dashboards make it easy to quickly filter & search for companies by the specific criteria that’s relevant to your needs.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default InteractiveDirectory
