export const utilities = {
  naviagetToElement: id => {
    console.log(id)
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollTo({ behavior: 'smooth' })
    }
  },
  convertDateToLocal: date => {
    var nameofMonth = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const localDate = new Date(Date.parse(date))
    const month = nameofMonth[localDate?.getMonth()]
    const year = localDate?.getFullYear()
    const day = localDate?.getDate()
    return day + ' ' + month + ' ' + year
  }
}
