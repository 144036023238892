
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Server_Error from "../images/500InternalServerError.png";

const Servererror = () => {
    return (
        <section className = "main">
            <section className = "blog-post nopage">
                <Container>
                    <Row>
                        <Col xl = {12} className = "text-center">
                            <div className = "nofound-img">
                                <img className = "server-error" src = {Server_Error} alt = "404_icon"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs = {12} className = "text-center">
                            <Link to = "/" className = "btn btn-main">
                                Back to home
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </section>
    )
}

export default Servererror;