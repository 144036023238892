import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Topbanner from "../components/top-banner/Topbanner";
import TrustedClient from "../components/client-carousel/client";
import Solutions from "../components/solution/Solution-sec";
import Services from "../components/services/Services";
import Tradeservices from "../components/services/Tradeservices";
import Testimonials from "../components/testimonials/Testimonial";
import Demobanner from "../components/demobanner/Demobanner";

import SupplyDemand from "../images/home/Supply-demand-b.svg";
import SatelliteData from "../images/home/SatelliteData-b.svg";
import Miningdata from "../images/home/Miningdata-b.svg";
import MachineLearning from "../images/home/machine-learning.svg";
import AISTracking from "../images/home/DockedShip.svg";
import SatelliteThermal from "../images/home/steelmillheat.svg";

const Home = ()=>{

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [])

    return(
    <section className="main">

        <Topbanner/>

        <TrustedClient/>

        <Solutions/>

        <section className="middle-top">
            <Container className = "text-cetner text-md-start">
                <Services image={SupplyDemand} header={'Real-Time Supply & Demand'} pra = {'Determine which companies are consuming, producing, or shipping more or less. Immediately assess how this impacts the supply & demand balance of your commodity sector in real-time.'}/>
                <Services image={SatelliteData} header={'Complete Trade Visibility'} pra = {`Who’s doing what with who, how much, when & where? Complete visibility on how much business your clients or suppliers are doing with your competitors or vice versa.`}/>
                <Services image={Miningdata} header={'Achieve Best Execution'} pra = {'Who’s overstocked (long)? Who’s understocked (short)? Cut out onerous back & forth negotiations with data- & fact-based intelligence. Know definitively where your counterparty stands.'}/>
            </Container>
        </section>

        <section className="middle-top middle-bottom">
            <Container className = "text-cetner text-md-start">
                <h1 className='text-center mb-3 mb-lg-5'>
                Technology that Delivers Results in Real Time
                </h1>
                <Tradeservices image={MachineLearning} header={'Very High-Resolution (VHR) Optical Satellite Imagery'} pra = {'Scalable AI/ML processing of near real-time VHR optical satellite imagery to monitor the expansion or contraction of bulk commodity stockpiles across the world.'} 
                button = {<><Link to = "/methodology" className = "btn-main">View Solution</Link></>}/>
                <Tradeservices image={AISTracking} header={'Terrestrial AIS and Satellite AIS (SAT-AIS)'} pra = {'Ultra-High-Frequency monitoring of company-specific dry bulk commodity seaborne shipments that tracks buyer/seller, payloads, berthing times, and loading rates.'} 
                button = {<><Link to = "/navigate-marine" className = "btn-main">View Solution</Link></>}/>
                <Tradeservices image={SatelliteThermal} header={'Thermal Infrared Satellite Imagery (TIRS)'} pra = {'Scalable AI/ML processing of near real-time thermal infrared satellite imagery to monitor the almost daily activity of global company-specific metal smelters.'} 
                button = {<><Link to = "/navigate-earth" className = "btn-main">View Solution</Link></>}/>
            </Container>
        </section>

        <Testimonials/>

        <Demobanner/>

    </section>
    );
}

export default Home;