import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Accordion from 'react-bootstrap/Accordion'
import $ from 'jquery'
import '../components/terms-services/terms-services.css'

import TermsServices from '../components/terms-services/terms-services-banner'
import TermsCollapse from '../components/terms-services/terms-collapse'
import { useEffect, useState } from 'react'
const Termcondiitons = () => {
  const [currentIndex, setCurrentIndex] = useState('#header0')
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo(0, 0)
    }, 400);
  }, [])

  useEffect(() => {
    $('.collapse').on('shown.bs.collapse', function (e) {
      var $card = $(this).closest('.accordion-item')
      var $open = $($(this).data('parent')).find('.collapse.show')

      var additionalOffset = 0
      if (
        $card.prevAll().filter($open.closest('.accordion-item')).length !== 0
      ) {
        additionalOffset = $open.height()
      }
      $('html,body').animate(
        {
          scrollTop: $card.offset().top - additionalOffset
        },
        500
      )
    })
  }, [currentIndex])

  const dropDown = [
    {
      header: '1. Introduction',
      desc: (
        <>
          <p>
            1.1 These terms and conditions of service (and any updates and
            supplements thereto) (“Terms”) govern the use of all Sites (as
            defined below) and related Services (as defined below) owned,
            operated or provided by Navigate Commodities (as defined below)
            and/or another Group Company (as defined below). These Terms
            constitute a binding agreement between you and Navigate Commodities
            and/or the relevant Group Company.
          </p>
          <p>
            1.2 Your use of the Sites and/or Services is conditional on you
            complying with these Terms and in using and/or accessing the Sites
            and/or Services, you signify your agreement to:
            <br />
            (a) these Terms (and any updates and supplements thereto);
            <br />
            (b) any additional terms and conditions that are posted on specific
            Sites or agreed to in relation to any Service (“Specific Terms”).
            (Specific Terms shall be deemed incorporated herein by reference,
            and to the extent of any conflict, the Specific Terms shall
            prevail); and
            <br />
            (c) all other operating rules, policies and procedures that may be
            published from time to time by us, and the additional terms and
            policies set out in Clause 28, each of which is incorporated herein
            by reference and each of which may be updated by us from time to
            time without notice to you.If you do not agree to any provision in
            these Terms, you are not permitted to, and must immediately
            discontinue, any use of the Sites and/or Services or any part
            thereof.
          </p>
          <p>
            Submission of registration information by you to us (where relevant)
            and whether through one of the Sites or otherwise will constitute an
            offer by you to become a Registered User (as defined below) or
            Subscriber (as defined below) subject to these Terms and any other
            terms applicable to the specific registration or subscription
            (including, without limitation, terms relating to the number of
            licensed users, the applicable charges and period of any
            subscription). You will not become a Registered User or Subscriber
            nor be entitled to access or receive Sites, Content or Services
            relating to the relevant registration or subscription unless and
            until we notify you of our acceptance of such offer, by providing
            you with a user name and password (or such other notification in
            accordance with the registration or subscription process on that
            Site and/or in respect of that Service).
          </p>
          <p>
            1.3 If you have any questions regarding these Terms, please contact
            us at{' '}
            <a href='mailto:compliance@navigatecommodities.com'>
              compliance@navigatecommodities.com
            </a>
            .
          </p>
          <br />
          <p>terms and policies and agree to be bound thereby.</p>
        </>
      ),
      id: 'header0'
    },
    {
      header: '2. Definitions',
      desc: (
        <>
          <p>
            2.1 “you/your” means (as appropriate) the Visitor, Registered User
            and/or Subscriber accessing a Site or using a Service.
          </p>
          <p>
            2.2 “we/us/our” means Navigate Commodities and/or a Group Company as
            the context requires.
          </p>
          <p>
            2.3 “Agent” means an employee, partner, director, contractor, agent
            or representative of any party.
          </p>
          <p>
            2.4 “Connected Networks” means certain third-party social networking
            sites (including without limitation Twitter and LinkedIn) which we
            may choose to affiliate with a certain Site.
          </p>
          <p>
            2.5 “Content” means all material, data, articles, information and
            products on the Sites or provided as part of a Service (including,
            without limitation, any Electronic Books or publications sent by
            post as part of a Service, but excluding, any Posted Material).
          </p>
          <p>
            2.6 “Deep Linking” means the potential linking to an internal or
            subsidiary page of a Site located one or several levels down from
            the home page.
          </p>
          <p>
            2.7 “Electronic Books” means any electronic book including without
            limitation e-books and yearbooks or other written material available
            for download from any of our Sites, e-mailed directly to you as part
            of a Service or disseminated by other electronic means for viewing
            without accessing a Site.
          </p>
          <p>
            2.8 “External Sites” means websites other than the Sites provided by
            Sponsors or other third parties.
          </p>
          <p>
            2.9 “Framing” means the bringing up of or presenting Content within
            another website.
          </p>
          <p>
            2.10 “Group Company” means Navigate Commodities, or any subsidiary
            or affiliated company of Navigate Commodities.
          </p>
          <p>
            2.11 “Intellectual Property” means all intellectual property rights
            whether registered or not, including patent rights, registered
            designs, design rights, copyrights, trademarks, database rights and
            all other intellectual rights of whatever nature in any relevant
            jurisdiction.
          </p>
          <p>
            2.12 “Interactive Areas” means bulletin boards, email services,
            discussion groups, messaging systems, including instant messaging
            facilities, and other public areas that allow interactivity between
            users of our Sites and/or feedback to be provided to us.
          </p>
          <p>
            2.13 “Investment” means any security, share, debenture, financial
            product or any other investment product.
          </p>
          <p>
            2.14 “Minimum Term” means the initial period of one year from the
            commencement of any subscription (or such other period as may be
            agreed in writing for any specific subscription).
          </p>
          <p>
            2.15 “Navigate Commodities” means Navigate Commodities Pte. Ltd., a
            company incorporated in Singapore having its registered address at
            5 Shenton Way, UIC Building, #10-01, Singapore 068808 and
            the authorised distributor of Sites and/or Services, owned, operated
            and/or provided by Navigate Commodities and/or another Group
            Company.
          </p>
          <p>
            2.16 “Posted Material” means the information and/or materials posted
            to Interactive Areas by you or any third party.
          </p>
          <p>
            2.17 “Privacy Policy” means our relevant privacy policy relating to
            your personal data and/or information.
          </p>
          <p>
            2.18 “Registered User” means a user of certain Sites and/or Services
            who has registered with us in order to access such Sites and/or
            Services under Clause 10.1(a).
          </p>
          <p>
            2.19 “Representative” means our suppliers, officers, employees,
            partners, affiliates, subsidiaries, successors and assigns, agents
            or representatives.
          </p>
          <p>2.20 “Services” means:</p>
          <p>
            (a) the supply of on-line or electronic information, publications
            and data products (including, without limitation, the delivery of
            the same over the internet, or via mobile phone apps or other
            digital devices);
          </p>
          <p>
            (b) the supply of hard-copy publications and other materials; and
          </p>
          <p>
            (c) other services relating to a Site (including, without
            limitation, any Interactive Areas).
          </p>
          <p>
            2.21 “Sites” means all websites owned, operated or provided by
            Navigate Commodities and/or another Group Company, whether under the
            name “Navigate Commodities” or otherwise (a non-exhaustive list of
            such websites is under Clause 25 below).
          </p>
          <p>
            2.22 “Social Media Page” means a page created, operated or otherwise
            provided by Navigate Commodities and/or another Group Company via a
            third-party social media site, network, application and/or service.
          </p>
          <p>
            2.23 “Software” means the software and associated Intellectual
            Property that is made available for download from our Sites.
          </p>
          <p>2.24 “Sponsors” means sponsoring organisations and advertisers.</p>
          <p>
            2.25 “Subscriber” means a user of our Sites and/or Services who has
            registered for our subscription Services under Clause 10.1(b).
          </p>
          <p>
            2.26 “Third Party Service(s)” means products or services provided by
            anyone other than Navigate Commodities and any Group Company.
          </p>
          <p>
            2.27 “Visitor” means a user who accesses our Sites without
            registration or subscription.
          </p>
        </>
      ),
      id: 'header1'
    },
    {
      header: '3. Who may use and/or access the Sites and/or Services',
      desc: (
        <>
          <p>
            3.1 The Sites and/or Services are intended for users eighteen (18)
            years of age and above. Registered Users or Subscribers who are
            individuals must be eighteen (18) years of age and above.
            Individuals who are under eighteen (18) years of age may only view
            material on any Site as a Visitor if their parent or guardian agrees
            to these Terms on their behalf.
          </p>
          <p>3.2 You represent and warrant that:</p>
          <p>(a) you have the capacity to agree to these Terms;</p>
          <p>
            (b) individual users are at least eighteen (18) years of age; and
          </p>
          <p>
            (c) (if applicable) you agree to these Terms on behalf of your child
            or ward who is under the age of eighteen (18) years.
          </p>
        </>
      ),
      id: 'header2'
    },
    {
      header: '4. Access of the Sites and/or Services by related entities',
      desc: (
        <>
          <p>
            4.1 You are responsible for bringing these Terms to the attention of
            anyone who may, through you, access a Site and/or Service or view or
            use any Content.
          </p>
          <p>
            4.2 To the extent any individual accesses a Site or uses a Service
            as an Agent of any legal entity which is a Subscriber, the Agent
            warrants that he or she has authority to agree to these Terms on
            behalf of the relevant legal entity.
          </p>
          <p>
            4.3 Some areas on a Site may only be available to Registered Users
            or Subscribers (see Clause 10 below).
          </p>
        </>
      ),
      id: 'header3'
    },
    {
      header: '5. Scope of licence',
      desc: (
        <>
          <p>
            5.1 In consideration of you agreeing to abide by these Terms, we
            grant you a non-exclusive, non-transferable licence, subject to
            these Terms, to use and/or access the Sites and/or Services.
          </p>
          <p>
            5.2 You acknowledge and agree that we may need to interrupt or
            suspend your use and/or access to the Sites and/or Services from
            time to time, for maintenance, technical or other reasons.
          </p>
          <p>
            5.3 You undertake that you will, and if you are a Subscriber, you
            will procure that any licensed user under your subscription will:
          </p>
          <p>
            (a) comply with all applicable laws in relation to the Sites and/or
            Services, including without limitation, laws relating to the use of
            Intellectual Property;
          </p>
          <p>
            (b) not use the Sites, Services and/or Content outside the scope of
            permitted use under Clause 6 below, nor infringe any Intellectual
            Property or other rights in or relating to the Site and/or the
            Services or of any third party;
          </p>
          <p>
            (c) not copy, reproduce, recompile, decompile, disassemble, reverse
            engineer, upload, download, transmit, create derivative works from
            or otherwise exploit or tamper with any Site or, subject to Clause
            20, any software embodied in any Site or Service;
          </p>
          <p>
            (d) not prevent or restrict the use of any Site and/or Service by
            other authorised users nor hack into or cause damage to any server
            or other equipment operated by us;
          </p>
          <p>
            (e) not remove or destroy any copyright, trade mark or other
            proprietary markings or notices in respect of the Sites and/or
            Services or on any data files, information, applications, text,
            audio, image or other files or materials produced, generated or
            displayed in respect of the Sites and/or Services;
          </p>
          <p>
            (f) not use scripts to automatically mass download any data,
            information or other content relating to the Sites and/or Services;
            and
          </p>
          <p>
            (g) not to engage in or allow the automatic gathering of information
            or the automatic extraction of data from the Sites and/or Services.
          </p>
        </>
      ),
      id: 'header4'
    },
    {
      header: '6. Our Intellectual Property',
      desc: (
        <>
          <p>
            6.1 You acknowledge and agree that all Intellectual Property rights
            relating to the Sites and/or Services (including all rights in and
            to the Content) belong to us or our third-party content providers.
            Our Intellectual Property is protected by the Intellectual Property
            laws of the relevant jurisdiction, including but not limited to,
            Singapore, United Kingdom, Hong Kong, and other jurisdictions. We
            may license the Content for third party use in our sole discretion.
          </p>
          <p>
            6.2 You may use the Sites, Services and/or the Content solely for
            your own personal use and benefit and not for resale or other
            transfer or disposition to any other person or entity. You may not
            use, reproduce, modify, transfer, exploit, distribute or dispose of
            any aspect of the Sites, Services and/or Content for any commercial
            purpose, whether direct or indirect (including, without limitation,
            by making Content available to anyone other than a Subscriber where
            such Content is accessible through subscription only).
          </p>
          <p>
            6.3 Subject to your compliance with these Terms, the limitations in
            Clause 6.2 above and further subject to Clause 17 in relation to
            Electronic Books, you may:
          </p>
          <p>
            (a) retrieve and display Content on a computer screen or other
            digital device, print a single copy of individual pages on paper
            (but not photocopy them) and store such pages in electronic form on
            disc or other digital media (but not on any server or other storage
            device connected to a network); and
          </p>
          <p>
            (b) use the “Email this story to a friend” option where the facility
            is available to email an article from a Site to other individuals
            without further charge, provided such other individuals also comply
            with the restrictions on use in Clause 6.2 above.
          </p>
          <p>
            6.4 Notwithstanding Clause 6.2, you may quote from or paraphrase
            extracts of the Content to the extent permitted and strictly in
            accordance with any applicable Specific Terms, or as otherwise
            permitted by law, on the condition that appropriate source and
            copyright attribution is given in each case.
          </p>
          <p>
            6.5 The trademarks ‘Navigate Commodities’, Navigator
            <sup>TM</sup>‘, ‘Forecastor<sup>TM</sup>’ and ‘Educator<sup>TM</sup>
            ‘ are the property of Navigate Commodities, and other
            trademarks/logos appearing on the Sites may belong to Navigate
            Commodities, other Group Companies or third parties. None of these
            marks may be used without the express prior written permission of
            the respective owners.
          </p>
          <p>
            6.6 Except as explicitly provided for in these Terms, distributing,
            sharing, copying, posting, scanning, forwarding, selling,
            publishing, retransmitting or otherwise providing access to any part
            of the Content to anyone without our express prior written
            permission, is not authorised and may be a violation of copyright
            laws.
          </p>
          <p>
            6.7 Any request for permission to republish, reprint or use any
            articles from any of the Sites or our trade marks for any purpose
            other than those permitted under this Clause 6 (or any relevant
            Specific Terms) should be sent to{' '}
            <a href='mailto:compliance@navigatecommodities.com'>
              compliance@navigatecommodities.com
            </a>
            .
          </p>
          <p>
            6.8 For the avoidance of doubt, where the provisions of any
            applicable law prohibit the extent to which certain activities can
            be restricted under this Clause 6, any such restrictions shall apply
            to the fullest extent permitted in accordance with such law.
          </p>
        </>
      ),
      id: 'header5'
    },
    {
      header: '7. Means of accessing the Sites and/or Services',
      desc: (
        <>
          <p>7.1 You may access a Site as follows:</p>
          <p>
            (a) if you go through a registration process on that Site (if
            available), as a Registered User (in accordance with Clause 10
            below);
          </p>
          <p>
            (a) if you go through a registration process on that Site (if
            available), as a Registered User (in accordance with Clause 10
            below);
          </p>
          <p>
            (b) if you register as a Subscriber to our subscription Services
            (which we may offer, in accordance with Clauses 10 and 12 below); or
          </p>
          <p>
            (c) by accessing the Site as a Visitor without registration under
            (a) or subscription under (b), and in such circumstances, your use
            of the Site or any Content (as defined below) constitutes your
            acceptance of these Terms.
          </p>
          <p>
            7.2 You are responsible for procuring the necessary equipment and
            the payment of telephone, internet and other charges necessary to
            access and/or use any Site and/or Service. We are not responsible
            for the reliability or continued availability of the telephone
            lines, internet and equipment you use to access and/or use a Site
            and/or Service.
          </p>
        </>
      ),
      id: 'header6'
    },
    {
      header: '8. Cross-jurisdiction provision of Content',
      desc: (
        <>
          <p>
            8.1 Any Content sent for delivery across jurisdictions may be
            subject to import duties and taxes collected at the final
            destination. All sales or use taxes, import duties or additional
            customs charges must be borne entirely by you. We have no control
            over these charges and we expressly disclaim any responsibility or
            liability for them. Customs policies vary widely from country to
            country, and you may wish to contact your local customs office for
            further information.
          </p>
          <p>
            8.2 Additionally, when ordering from us or any other Group Company,
            you are considered the importer of record and must comply with all
            law and regulations of the country in which you are receiving the
            goods. Our international customers should be aware that cross-border
            deliveries are subject to opening and inspection by customs
            authorities. We may provide certain order, delivery and product
            information (such as the product’s title) to our international
            carriers and such information may be communicated by the carriers to
            customs authorities in order to facilitate customs clearance and
            compliance with local laws. If the order is a gift, the package will
            be marked ‘Gift’ but the cost or value of the item may still be
            stated on the customs form and/or the package.
          </p>
        </>
      ),
      id: 'header7'
    },
    {
      header: '9. Our provisions of the Sites and/or Services',
      desc: (
        <>
          <p>
            9.1 Subject to and without limitation to Clause 21, we will exercise
            reasonable skill and care in our provision of the Sites and/or
            Services to Registered Users and Subscribers, but from time to time
            faults may occur, in which case, we will use reasonable efforts to
            rectify them as soon as reasonably practicable.
          </p>
          <p>
            9.2 We will endeavour to ensure all subscriptions or other Services
            (where applicable) are priced correctly on the Sites and/or in the
            Content. However, errors may occur. If such a pricing error is
            discovered, we reserve the right to give you the option of either
            cancelling your order for any particular subscription or other
            Service or reconfirming your order at the correct price.
          </p>
        </>
      ),
      id: 'header8'
    },
    {
      header: '10. Registrations and subscriptions',
      desc: (
        <>
          <p>
            10.1 To access certain Sites (or specific Content within certain
            Sites), and/or to receive certain Services, you must register with
            us as either a Registered User or a Subscriber, as follows:
          </p>
          <p>
            (a) for certain Sites, where Content and/or Services are available
            free of charge once you register with us, you may access or receive
            such Content and/or Services subject to these Terms as a Registered
            User; or
          </p>
          <p>
            (b) in certain instances, where charges are payable for the receipt
            of certain Services (which may include access to certain Sites and
            Content), you may access such Services subject to these Terms as a
            Subscriber who has paid the relevant fees and/or charges (in
            accordance with Clause 12 below) for a specified period of time, a
            specified number of licensed users and/or a site licence.
          </p>
          <p>
            10.2 In order to register with us, you must provide us with accurate
            and complete information. You represent to us that such information
            is true, accurate and up to date, and it is your responsibility to
            inform us of any changes to that information (including, without
            limitation, your email address), by updating your details on the
            relevant section of the applicable Site or by contacting us using
            our contact details on the applicable Site. If you do not provide
            accurate and complete information to us when you register, we may
            not be able to provide the Sites, Services and/or Content that you
            request for.
          </p>
          <p>
            10.3 In certain instances, before you can make use of certain
            Services associated with a particular Site as a Registered User, you
            may need to create an account connected through Connected Networks.
            You understand that the creation of a Registered User account
            through the Connected Networks will require a connection to be
            created between the Site and the Connected Networks to facilitate
            your participation and activities on the Site.
          </p>
          <p>
            10.4 When you register, you will be given one or more usernames and
            passwords, as appropriate for your registration or subscription to
            the relevant Site and/or Service.
          </p>
          <p>
            10.5 Except to the extent that a username and password is intended
            for more than one licensed user as expressly agreed to by us in
            writing, the following are strictly prohibited:
          </p>
          <p>
            (a) the sharing of their username and/or password (whether
            intentional or otherwise) by a Registered User, Subscriber or
            licensed user under any registration and/or subscription with any
            other person or entity (including with any Agent of such user);
            and/or
          </p>
          <p>
            (b) access through a single username and password being made
            available to multiple users (whether on the same network or
            otherwise).
          </p>
          <p>
            For the avoidance of doubt, the above will constitute a breach of
            these Terms and may result in the immediate termination of your
            registration, subscription and/or licence, and without limitation, a
            claim for damages or otherwise, being brought by us against you.
          </p>
          <p>
            10.6 To the extent that the terms of a multiple-user licence
            restricts licensed users to a particular category of individuals
            (e.g. employees of your organisation), you must notify us
            immediately, using the customer service contact details applicable
            to your registration and/or subscription, if a licensed user ceases
            to fall within the specified category.
          </p>
          <p>
            10.7 Notwithstanding the restrictions in Clause 10.5 above, you are
            responsible for all access and use of any Site and/or Service by you
            or anyone else using any of your user names and passwords and for
            preventing unauthorised use of any of your user names and passwords.
            If you believe that there has been any breach of security and/or
            unauthorised use (such as the disclosure, theft or unauthorised use
            of any user name, password or any payment information), you must
            notify us immediately by emailing{' '}
            <a href='mailto:compliance@navigatecommodities.com'>
              compliance@navigatecommodities.com
            </a>
            .
          </p>
        </>
      ),
      id: 'header9'
    },
    {
      header: '11. Information relating to your subscription',
      desc: (
        <>
          <p>
            11.1 We reserve the right to access and monitor password-protected
            information, including any information which you upload or input and
            any information concerning your activities in relation to a Service,
            a Site and/or any Content, in order for us to fulfil our obligations
            in respect of these Terms, to protect or comply with our legal
            rights and obligations and for other purposes as set out in our
            Privacy Policy.
          </p>
          <p>
            11.2 We may use cookies and similar technical tools as set out in
            our Privacy Policy to collect information about your use and/or
            access of our Sites and/or Services and to display advertisements
            that may be of interest to you and for such other purposes as may be
            set out in our Privacy Policy. Our Privacy Policy sets out further
            details of our use of cookies and similar technical tools.
          </p>
          <p>
            11.3 We may also use technical tools to track the distribution of
            Software as well as files and/or data which constitute the Content.
            This shall solely be for the purposes of protecting our legal rights
            and obligations and/or our Intellectual Property.
          </p>
          <p>
            11.4 As a Registered User and/or Subscriber, you further agree that
            we may refer to the name of your business and to the Services we
            have provided to you when marketing our business.
          </p>
        </>
      ),
      id: 'header10'
    },
    {
      header: '12. Subscription charges and refund policy',
      desc: (
        <>
          <p>
            12.1 Subscribers must pay certain charges in order to receive the
            relevant subscription Service (unless we expressly agree to a trial
            period or otherwise). In most instances, details of applicable
            charges can be found on the specific Sites relating to the relevant
            subscription Service. Where charges are not published on a Site, the
            applicable charges will be as agreed between you and us. All
            payments (including any applicable taxes) must be made in any
            currency that we may specify. We may direct you to make payments to
            another Group Company or third-party payment agent. If we (or our
            designated agent) do not receive payment for any charges due, we may
            immediately suspend your rights in relation to the relevant Service
            (including any relevant Site and/or Content). You are responsible
            for the payment of all charges associated with the use of the
            Service, Site and/or Content using your username and password.
          </p>
          <p>
            12.2 If we cancel and/or terminate your use and/or access in
            relation to any subscription Service in accordance with Clause 13.6
            below, unless you are or have been in breach of these Terms, we
            shall refund you on a pro rata basis any subscription charges you
            have paid in advance (for the relevant cancelled or terminated
            subscription) which relate to any unexpired part of the subscription
            period at the date of cancellation. You shall continue to be
            responsible for any fees or other charges incurred by you until the
            cancellation and/or termination of your use and/or access in
            relation to the relevant subscription Service.
          </p>
          <p>
            12.3 If you cancel and/or terminate your subscription to any Service
            in accordance with Clause 13.3 below prior to the end of the
            subscription period:
          </p>
          <p>
            (a) subject to Clauses 12.3(b) and 12.3(c) below, you shall be
            entitled to a refund on a pro rata basis of any subscription charges
            you have paid in advance for any complete months remaining of the
            subscription period at the date of cancellation beyond the Minimum
            Term;
          </p>
          <p>
            (b) we shall charge a reasonable administrative fee which shall be
            deducted from the amount of refund due; and
          </p>
          <p>
            (c) if you were granted any discount on the full value of the
            subscription charges for the subscription period, we may recalculate
            the value of the Minimum Term of the subscription period on the
            basis that no discount was applied to such Minimum Term.
          </p>
        </>
      ),
      id: 'header11'
    },
    {
      header: '13. Term and cancellation',
      desc: (
        <>
          <p>
            13.1 Subject to your continued compliance with these Terms and to
            certain exceptions, access to our Sites and/or Services is on an
            ongoing basis and is not subject to any particular time limit.
          </p>
          <p>
            13.2 As a Subscriber, subject to these Terms, you may lose access to
            the relevant subscription Site and/or Service upon the expiration of
            your subscription.
          </p>
          <p>
            13.3 As a Subscriber, you may also cancel and/or terminate your
            subscription by giving us at least thirty (30) days’ written notice
            by post or email. The cancellation date as specified in such notice
            has to be a date after the Minimum Term and such notice is to be
            sent to the customer service contact details applicable to your
            subscription. You should quote the subscription reference number and
            any other relevant details. There may be a period after the
            cancellation date during which you continue to receive publications
            sent to you as part of your subscription. However, you will not be
            charged for such publications. For the avoidance of doubt, you shall
            not be entitled to cancel your subscription prior to the end of the
            Minimum Term.
          </p>
          <p>
            13.4 As a Registered User, subject to these Terms, you may cancel
            your registration at any time by electronic notice to us using the
            contact information on the relevant Site or otherwise notified to
            you.
          </p>
          <p>
            13.5 As a Visitor, subject to these Terms, you may cancel your
            access to and use of any Site (or receipt of any related Service),
            at any time, by discontinuing your access and/or use of that Site,
            any related Service and/or any associated Content.
          </p>
          <p>
            13.6 We may cancel and/or terminate your (and, if you are a
            Subscriber, your licensed users’) access to and use of any Site and
            Content and/or your receipt of any Service on these Terms
            (including, without limitation, any registration or subscription) at
            any time:
          </p>
          <p>
            (a) if you are a Registered User, by email notice to you, or
            immediately, without notice to you, if you breach any provision of
            these Terms;
          </p>
          <p>
            (b) if you are a Subscriber, by email notice to you, or immediately,
            without notice to you, if you or any of your licensed users breach
            any provision of these Terms; or
          </p>
          <p>(c) if you are a Visitor, immediately, without notice to you.</p>
          <p>
            13.7 To the extent that the Specific Terms for any subscription
            entitle you to download and store certain Content, upon cancellation
            and/or termination of such subscription by either you or us, you
            agree to delete all such Content stored in your possession or under
            your control within 30 days of cancellation and/or termination,
            unless otherwise agreed with us in writing, or as required by
            applicable law. For the avoidance of doubt, where applicable law
            does require the retention of certain Content beyond the 30-day
            period, you agree that it shall only be retained to the extent as
            required under such law and shall not be used for commercial
            purposes.
          </p>
          <p>
            13.8 Cancellation and/or termination of your access to and use of
            any Site and Content and/or your receipt of any Service shall not
            affect any provision of this agreement which is expressly or by
            implication intended to come into effect or to continue in effect
            after such cancellation, termination and/or expiry, including but
            not limited to Clauses 6.1, 6.5, 6.6, 12.2, 12.3, 13.8, 13.9, 15.2,
            15.3, 15.7, 19.4, 21, 22 and 27.
          </p>
          <p>
            13.9 Cancellation and/or termination of your access to and use of
            any Site and Content and/or your receipt of any Service on these
            Terms (including, without limitation, any cancellation and/or
            termination of any registration and/or subscription), shall not
            affect our right to receive any outstanding sums due to us at the
            date of cancellation and/or termination, and any such sums shall be
            due and payable to us within fifteen (15) days of such cancellation
            and/or termination.
          </p>
        </>
      ),
      id: 'header12'
    },
    {
      header: '14. Email communications from us',
      desc: (
        <>
          <p>
            14.1 We may give you the option of requesting Content via email.
            Your registration or subscription for such an email Service and use
            of the Content received via email is subject to these Terms. Subject
            to Clause 14.3 below, you may subsequently unsubscribe from such an
            email Service.
          </p>
          <p>
            <strong>
              14.2 We, our Group Companies or our Third-Party Service providers
              may also send you emails to keep you updated on developments
              relating to the Sites and/or Services. Subject to Clause 14.3
              below, you may unsubscribe from the receipt of such emails.
            </strong>
          </p>
          <p>
            14.3 Notwithstanding that you may unsubscribe from the receipt of
            emails under Clause 14.1 and/or 14.2 above, we retain the right to
            send you informational email messages relating to your account or
            administrative notices regarding the Sites and/or Services, to the
            extent as permitted under applicable laws.
          </p>
        </>
      ),
      id: 'header13'
    },
    {
      header: '15. Interactive Areas on our Sites and/or Services',
      desc: (
        <>
          <p>
            15.1 A Site and/or a Social Media Page may contain Interactive Areas
            including Posted Material. We do not control and are not responsible
            for Posted Material, and as a result, cannot guarantee the veracity
            or accuracy of any Posted Material. All use of the Interactive Areas
            and any use of or reliance on Posted Material is at your own risk.
          </p>
          <p>
            15.2 You hereby grant us, and, if you are a Subscriber, agree to
            procure the grant to us by all licensed users under your
            subscription of, a non-exclusive, perpetual, royalty-free licence to
            use, reproduce, modify and/or sub-license all or part of your Posted
            Material, including to sub-license Group Companies or third parties
            to use the same. We may without notice to you or any third party
            delete, move or edit any Posted Material or part of it.
          </p>
          <p>
            15.3 To the extent permitted under applicable law, you hereby waive,
            and, if you are a Subscriber, agree to procure the waiver by all
            licensed users under your subscription of, all moral rights or
            rights of a similar nature in any jurisdiction (as may be
            applicable) in respect of any Posted Material.
          </p>
          <p>
            15.4 You are responsible for the content of your Posted Material and
            you may not within the Interactive Areas or otherwise in relation to
            a Service and/or a Site (including, without limitation, any email
            Service), post, publish, link to, upload, download, send,
            distribute, use or re-use any information or material:
          </p>
          <p>
            (a) which is or could be taken to be the provision of advice
            (including, without limitation, investment advice) or a
            recommendation to buy or refrain from buying a particular Investment
            or which has the purpose of affecting the price or value of any
            Investment;
          </p>
          <p>
            (b) obtained in breach of confidence or which contains confidential
            information or infringes any Intellectual Property rights or rights
            of privacy or other rights of any third party;
          </p>
          <p>
            (c) which is offensive, threatening, abusive, indecent, defamatory,
            obscene, degrading or menacing, or is otherwise contrary to
            applicable law or regulation or promoting an illegal act;
          </p>
          <p>
            (d) which constitutes unsolicited advertising or promotional
            material, including but not limited to any chain emails, unsolicited
            commercial emails, unsolicited bulk email, “spam” or mail bombs;
            and/or
          </p>
          <p>
            (e) which constitutes or contains a virus or other harmful component
            or malware.
          </p>
          <p>15.5 Without prejudice to Clause 15.4 above, you may not:</p>
          <p>
            (a) use any Interactive Area to carry out criminal, fraudulent or
            illegal activities;
          </p>
          <p>
            (b) use any Interactive Area to impersonate any person, company,
            group or entity or misrepresent a relationship to or with any of the
            same;
          </p>
          <p>
            (c) disguise the origin of any message posted in any Interactive
            Area; nor
          </p>
          <p>
            (d) collect, store, disclose or otherwise process any personal data
            in relation to your use of any Interactive Area without the
            knowledge of the relevant individual or in breach of applicable data
            protection laws.
          </p>
          <p>
            15.6 You must comply with any additional rules which may, from time
            to time, be issued by us at particular sections of the Interactive
            Areas, and such rules shall be deemed incorporated into these Terms.
          </p>
          <p>
            15.7 By accessing the Interactive Areas, you agree to indemnify and
            hold us harmless against all claims, damages, costs and expenses
            (including legal and other professional fees) which we may incur as
            a result of and/or in relation to any of your Posted Material.
          </p>
          <p>
            15.8 We reserve the right to remove Posted Material at any time, for
            any reason, and without notice, including without limitation for any
            failure to comply with the provisions of this Clause 15. If any
            Posted Material fails to comply with the provisions of this Clause
            15, we reserve the right to prevent you from accessing the
            Interactive Areas. Notwithstanding the foregoing, we are under no
            obligation to review, monitor, delete and/or edit Posted Material.
          </p>
          <p>
            <strong>
              15.9 Our Representatives may also submit Posted Material to the
              Interactive Areas. In doing so, our Representatives shall act in a
              personal capacity and any views they might express shall not be
              considered our views nor the views of a financial or other
              professional advisor.
            </strong>
          </p>
          <p>
            15.9 Our Representatives may also submit Posted Material to the
            Interactive Areas. In doing so, our Representatives shall act in a
            personal capacity and any views they might express shall not be
            considered our views nor the views of a financial or other
            professional advisor.
          </p>
        </>
      ),
      id: 'header14'
    },
    {
      header: '16. Copyright violations in respect of Posted Content',
      desc: (
        <>
          <p>
            16.1 If you believe that your work has been copied and posted on any
            of our Sites in a way that constitutes copyright infringement, you
            should provide our copyright agent (contact details below) with a
            written notice that sets out the details of the alleged
            infringement. Such a notice must contain the following:
          </p>
          <p>
            (a) a description of the copyrighted work that you believe has been
            infringed;
          </p>
          <p>
            (b) a description of the material that you claim is infringing the
            copyrighted work and a detailed description of where it is located
            on our Site;
          </p>
          <p>(c) your address, telephone number, and email address;</p>
          <p>
            (d) a written statement by you that you have a good faith belief
            that the disputed use is not authorised by the copyright owner, its
            agent, or the law;
          </p>
          <p>
            (e) a statement by you, made under penalty of perjury, that the
            above information in your notice is accurate and that you are the
            copyright owner or authorised to act on the copyright owner’s
            behalf; and
          </p>
          <p>
            (f) your signature as the copyright owner or person authorised to
            act on the copyright owner’s behalf.
          </p>
          <p>
            16.2 Please send such written notice to our copyright agent at the
            following address:
          </p>
          <p>By mail:</p>
          <p>Navigate Commodities Pte. Ltd.,</p>
          <p>5 Shenton Way,</p>
          <p>UIC Building, #10-01,</p>
          <p>Singapore 068808</p>
          <p>
            16.3 For the avoidance of doubt, the process set out in this Clause
            should only be used to report a claim of copyright infringement as
            relating to Clause 16.1. Messages related to other matters will not
            receive a response through this process.
          </p>
        </>
      ),
      id: 'header15'
    },
    {
      header: '17. Electronic Books',
      desc: (
        <>
          <p>
            17.1 Subject to Clause 6.3, in the event that you purchase an
            Electronic Book from one of the Sites, you are granted a
            non-exclusive right to:
          </p>
          <p>
            (a) download the Electronic Book for storage and display on no more
            than three electronic devices; and
          </p>
          <p>(b) obtain a single hardcopy printout of the Electronic Book.</p>
          <p>
            17.2 Electronic Books are non-refundable, except for where the
            product was provided to you in error for reasons reasonably
            attributable to us.
          </p>
        </>
      ),
      id: 'header16'
    },
    {
      header: '18. Linking',
      desc: (
        <>
          <p>
            18.1 You may create a link from your website to the home page of
            certain Sites (where they indicate that you may do so) provided that
            you complete the linking registration form available at the
            applicable Site (or by contacting us), and provided that you do not
            link to the Site from any website containing any material which
            would be in breach of Clauses 5.3(b) to 5.3(g) above or which is
            otherwise unlawful.
          </p>
          <p>
            18.2 Upon linking to any Site pursuant to these Terms, we shall
            grant you a non-exclusive, non-transferable, royalty-free license to
            use our trademarks solely for the purposes of providing an
            underlined, textual link from your website to the Site. Without
            prejudice to Clause 6.5, no other use of our trademarks or name is
            permitted without our express prior written permission.
          </p>
          <p>
            18.3 Deep Linking or Framing is not permitted without our express
            prior written permission. We may, but shall not be obliged to, give
            permission in respect of such Deep Linking or Framing in our sole
            discretion. You must seek and obtain our express prior written
            permission before Deep Linking or Framing the Site and/or any
            Content by contacting us and providing us with:
          </p>
          <p>(a) your name, email address and telephone number;</p>
          <p>(b) the name of your company;</p>
          <p>
            (c) the web address(es) where the proposed Deep Linking or Framing
            will occur; and
          </p>
          <p>
            (d) the specific proposal and details about the contemplated Deep
            Linking or Framing.
          </p>
        </>
      ),
      id: 'header17'
    },
    {
      header:
        '19. Advertisements, sponsorship, links and third-party transactions',
      desc: (
        <>
          <p>19.1 A Site and/or a Service may contain:</p>
          <p>(a) links to External Sites; and/or</p>
          <p>
            (b) advertisements for, and/or the opportunity for you to purchase
            Third Party Services or products or services from us and/or our
            Group Companies.
          </p>
          <p>
            We shall reasonably endeavour to highlight such links although some
            External Sites may be co-branded with ours.
          </p>
          <p>
            19.2 You should also that our Sites may be supported by Sponsors
            whose names appear on the Site and whose own websites may be
            accessed by internet links from that Site.
          </p>
          <p>
            19.3 External Sites and Third-Party Services are not maintained or
            controlled by us and we are therefore not responsible for the
            availability, content and/or accuracy of External Sites or
            Third-Party Services. We do not:
          </p>
          <p>
            (a) make any warranty, express or implied, with respect to the use
            of the links provided on, or to, a Site or Service;
          </p>
          <p>
            (b) guarantee the accuracy, completeness, usefulness or adequacy of
            External Sites or Third-Party Services; or
          </p>
          <p>
            (c) make any endorsement, express or implied, of any External Sites
            or Third-Party Services.
          </p>
          <p>
            Any advertisement or promotion on a Site of a Third-Party Service is
            not an inducement by us for you to enter into any agreement with the
            relevant third party.
          </p>
          <p>
            19.4 Any communications or contracts relating to Third Party
            Services, payment for and delivery of Third-Party Services, and any
            other associated terms, conditions, warranties or representations
            are exclusively between you and the relevant third parties. You
            agree not to hold us liable for any loss or damage incurred as the
            result of any such contracts, communications or other dealings
            and/or the presence of such third parties on any Site.
          </p>
        </>
      ),
      id: 'header18'
    },
    {
      header: '20. Software in respect of the Sites and/or Services',
      desc: (
        <>
          <p>
            20.1 All rights in the Software (including but not limited to
            Intellectual Property rights) are owned by us and/or our suppliers.
          </p>
          <p>
            20.2 Your use of the Software is governed by the terms of any
            licence agreement that may accompany, or be included with, the
            Software.
          </p>
          <p>
            20.3 If you do not agree with the terms of such licence agreement,
            you may not install or use the Software.
          </p>
        </>
      ),
      id: 'header19'
    },
    {
      header: '21. Limitation of liability',
      desc: (
        <>
          <p>
            21.1 Without prejudice to the remaining provisions of this Clause,
            you acknowledge and agree that your use and access of the Sites,
            Services and/or Content is entirely at your own risk and you
            acknowledge that all Sites, Content and Services are provided on an
            “as is” and “as available” basis. The Sites, Services and/or Content
            are made available for your general information only and the Sites,
            Services and/or Content are not intended to address your particular
            requirements (including, but not limited to, trading requirements).
          </p>
          <p>
            21.2 The Sites, Services and/or Content do not constitute any form
            of advice, recommendation or arrangement by us (including without
            limitation, investment advice or an offer or solicitation to buy or
            sell any Investment) and is not intended to be relied upon by users
            in making (or refraining from making) any specific investment
            decision and/or any other decision. You should obtain appropriate
            independent advice before making any such decision.
          </p>
          <p>
            21.3 The Sites, Services and/or Content may include inaccuracies
            and/or typographical errors. Changes are periodically made to the
            Sites, Services and/or Content. Save as provided for in these Terms,
            neither we nor our licensors and/or Representatives make any
            representations and/or warranties, and to the fullest extent
            permitted by law, exclude all implied warranties (including but not
            limited to, warranties of satisfactory quality, title and fitness
            for a particular purpose) in respect of, but not limited to:
          </p>
          <p>
            (a) the suitability of the information relating to the Sites,
            Services and/or Content;
          </p>
          <p>
            (b) the accuracy, availability, reliability, completeness or
            timeliness of the Sites, Services and/or Content;
          </p>
          <p>
            (c) the results obtained from accessing and using any Site, Service
            and/or Content.
          </p>
          <p>21.4 Furthermore, we do not represent or warrant that:</p>
          <p>
            (a) the functions contained in the Sites, Services and/or Content
            will be uninterrupted or error-free;
          </p>
          <p>(b) defects (if any) will be corrected;</p>
          <p>
            (c) the Sites, Services and/or Content or the servers that make them
            available are free of viruses or other harmful components; nor
          </p>
          <p>
            (d) the use of any of the Sites, Services and/or Content will not
            infringe the intellectual property rights of any third party.
          </p>
          <p>
            21.5 Neither we, nor any our Representatives will be liable to you
            for:
          </p>
          <p>
            (a) any incidental, punitive, indirect, special or consequential
            damage, loss or expenses, including but not limited to any loss of
            business, contracts, revenue, or profits, any business interruption,
            security breach, loss of data, loss of goodwill or reputation or
            other pecuniary loss suffered by you, even if we or any of our
            Representatives have been advised of their possible existence; nor
          </p>
          <p>
            (b) any direct damage, loss or expense arising from a loss of
            customers, loss of profits, loss of anticipated profits, loss of
            savings, a security breach or a loss of data.
          </p>
          <p>
            21.6 Without prejudice to Clause 21.5, neither we nor any
            Representative shall be liable to you for any direct or indirect
            damage, loss or expense suffered by you as a result of:
          </p>
          <p>
            (a) any viruses, worms, “Trojan horses” or similar programs, denial
            of service attack, spamming or hacking, or consequential damages, or
            any claim against us by any other party; or
          </p>
          <p>
            (b) any fault, inaccuracy, omission, delay or any other failure in a
            Site, Service and/or Content caused by your computer equipment or
            arising from your use of the Sites, Services and/or Content on such
            equipment; or
          </p>
          <p>
            (c) any inaccuracies, errors in, and/or omissions from any Site,
            Service and/or Content including, but not limited to, quotes,
            commodity and/or financial data; or
          </p>
          <p>
            (d) any delays, interruptions or short-term or immaterial errors in
            the transmission or delivery of any Site, Service and/or Content; or
          </p>
          <p>
            (e) any additions, deletions or changes made to the Sites, Services
            and/or Content at any time.
          </p>
          <p>
            21.7 Without prejudice to Clauses 21.5 and 21.6 and except where you
            are a Subscriber who has paid the relevant subscription charges (but
            subject to these Terms), neither we nor any Representative shall be
            liable to you for any direct or indirect loss, damage or expenses
            arising by any reason of non-performance by us of these Terms or any
            other provision and/or term of our agreement with you.
          </p>
          <p>
            21.8 Without prejudice to the limitation of liability provisions
            above, in the event that you, as a Subscriber, incur any loss,
            damage or expense arising out of your use of any Site, Content
            and/or a Service, you agree that our liability to you shall be
            limited to the sum of the subscription fees payable by you for
            Services provided in the twelve (12) month period preceding the date
            on which the claim arose (which, for the avoidance of doubt, shall
            not include, (a) any fees paid during such period for Services
            provided before such period, nor (b) any fees paid in advance during
            such period for Services provided after such period).
          </p>
          <p>
            21.9 You acknowledge and agree that we are not responsible for the
            investigation, defence, settlement, and discharge of any third-party
            claim for intellectual property infringement, breach of contract or
            otherwise that is occasioned or committed by you.
          </p>
          <p>
            21.10 Nothing in these Terms shall limit or exclude our liability
            for any liability that cannot be excluded or limited by applicable
            laws.
          </p>
        </>
      ),
      id: 'header20'
    },
    {
      header: '22. Indemnity',
      desc: (
        <>
          <p>
            22.1 You agree to indemnify and hold us, our Representatives,
            licensors and sub-contractors harmless from and against all costs,
            claims, damages, loss, liability, demand and expenses (including
            economic loss, accounting fees, and court and legal costs assessed
            on a solicitor-client basis) which we might suffer or incur, arising
            whether directly or indirectly, by reason of:
          </p>
          <p>
            (a) your negligent act or omission, wilful default, misconduct or
            fraud;
          </p>
          <p>(b) your breach of any provision in these Terms; and/or</p>
          <p>(c) your failure to comply with applicable laws;</p>
          <p>
            22.2 For the avoidance of doubt, this indemnity shall extend
            (without limitation) to any damage and/or loss which we may suffer
            as a result of the use by third parties of your username and
            password, except in circumstances where you can demonstrate to our
            satisfaction that such third party obtained the relevant details
            through no fault (including, without limitation, negligence) of your
            own.
          </p>
        </>
      ),
      id: 'header21'
    },
    {
      header: '23. Breach and termination',
      desc: (
        <>
          <p>
            23.1 Notwithstanding any other provision in these Terms, failure to
            comply with any provision under these Terms constitutes a material
            breach. We will determine, in our sole discretion, whether any such
            breach has occurred. Where we suspect or determine that such a
            breach has occurred, or if there arise other issues which we
            consider, in our sole discretion, to be disruptive to our business
            and reputation, we may take such action as we deem appropriate at
            any time, with or without prior notice to you, including all or any
            of the following:
          </p>
          <p>(a) issuance of a warning to you;</p>
          <p>
            (b) restricting, suspending or terminating your use and/or access as
            a Visitor, Registered User and/or Subscriber and/or your access to
            and/or use of the Sites, Services and/or Content, or any part
            thereof;
          </p>
          <p>
            (c) taking legal steps against you and/or instituting legal
            proceedings against you for your breach; and/or
          </p>
          <p>
            (d) the disclosure of such information related to your breach to law
            enforcement and/or other governmental authorities as we may feel is
            necessary.
          </p>
          <p>
            23.2 For the avoidance of doubt, you shall not be entitled to any
            refund of any payment made by you, in the event of the restriction,
            suspension and/or termination of your use and/or access pursuant to
            Clause 23.1 (for any reason whatsoever), or in the event that we
            take any action pursuant to Clause 23.1 above.
          </p>
          <p>
            23.3 Upon a termination, pursuant to Clause 23.1 above, all rights
            granted to you hereunder shall immediately cease and terminate, and
            you must immediately cease your access to and/or use of the Sites,
            Services and/or Content, or any part thereof.
          </p>
        </>
      ),
      id: 'header22'
    },
    {
      header: '24. Variations',
      desc: (
        <>
          <p>
            24.1 We may modify, amend or revise these Terms at any time in the
            exercise of our sole discretion, with or without notice to you, and
            you agree to be bound by all such modifications, amendments and/or
            revisions (“Variations”). Such Variations will be notified to you by
            being posted on the Sites and Variations which are in our view,
            material, will be emailed to our Registered Users and Subscribers.
            You are responsible for checking the Sites regularly for any posted
            Variations. A Variation will come into effect immediately upon the
            Variation being posted on the relevant Site and/or having been
            emailed to Registered Users and Subscribers (or any combination of
            both).
          </p>
          <p>
            24.2 If you do not agree to any Variation to these Terms, you are to
            stop accessing and/or using the Sites, Services and/or Content
            immediately. Your continued access and/or use of the Sites, Services
            and/or Content after a Variation has come into effect will
            constitute your assent to and acceptance of the Variation and the
            modified, amended and/or revised Terms.
          </p>
          <p>
            24.3 We may also update, change, remove or discontinue access to any
            of the Sites, Services and/or Content or change any aspect,
            features, component or content of the Sites, Services and/or Content
            at any time, for any reason, with or without notice to you, in the
            exercise of our sole discretion, without liability. We therefore do
            not guarantee that any specific aspect, content, component and/or
            feature will always be available for the Sites, Services and/or
            Content. You agree and acknowledge that such changes may result in
            you being unable to access the whole or any part of our Sites,
            Services and/or Content.
          </p>
        </>
      ),
      id: 'header23'
    },
    {
      header: '25. Non-exhaustive list of Sites and their domains',
      desc: (
        <>
          <p>
            25.1 Our Sites are located on the internet under, but not limited
            to, the following domains:
          </p>
          <p>
            <a href='https://navigatecommodities.com'>
              https://navigatecommodities.com
            </a>
          </p>
          <p>
            25.2 The domain names for our Sites may change from time to time.
          </p>
        </>
      ),
      id: 'header24'
    },
    {
      header: '26. Notices',
      desc: (
        <>
          <p>
            26.1 Any notice which is required to be given pursuant to these
            Terms shall be made by email or first-class post, in the case of
            you, to the address provided on your registration form and, in the
            case of us, to the relevant contact as given in these Terms, to the
            address posted on the relevant Site or otherwise notified to you in
            relation to any relevant Service. A copy of any notice given by you
            should also be sent to Navigate Commodities at{' '}
            <a href='mailto:compliance@navigatecommodities.com'>
              compliance@navigatecommodities.com
            </a>
            .
          </p>
          <p>
            26.2 Any such notice shall be deemed to have been received by the
            recipient, if sent by post, on the third day after posting
            (excluding days on which no mail deliveries are normally made), and
            if sent by email, on the day of transmission (and if on a day on
            which businesses in the recipient’s jurisdiction are not open for
            business, on the next business day).
          </p>
        </>
      ),
      id: 'header25'
    },
    {
      header: '27. General',
      desc: (
        <>
          <p>
            27.1 These Terms (including any terms incorporated by reference in
            these Terms) constitute the entire agreement between us in relation
            to your access to and use of the Sites, Services and/or Content and
            supersedes all prior agreements, negotiations and discussions
            between you and us relating to the same.
          </p>
          <p>
            27.2 Headings in these Terms are solely for convenience, and shall
            have no legal meaning or effect.
          </p>
          <p>
            27.3 An entity who is not a party to these Terms has no right under
            the Contracts (Rights of Third Parties) Act (Chapter 53B) of
            Singapore to enforce and/or enjoy the benefit of any provision
            and/or term of these Terms.
          </p>
          <p>
            27.4 These Terms are governed by and construed in accordance with
            the laws of the Republic of Singapore. Any dispute concerning these
            Terms shall be subject to the exclusive jurisdiction of the Courts
            of Singapore.
          </p>
          <p>
            27.5 If any provision of these Terms is found to be invalid by any
            court having competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the remaining provisions
            of these Terms, which shall remain in full force and effect.
          </p>
          <p>
            27.6 We shall not be liable for any non-performance, error,
            interruption or delay in the performance of our obligations, the
            provision of the Sites, Services and/or Content (or any part
            thereof) or in the operation of the Sites, Services and/or Content,
            or any unavailability of the Sites, Services and/or Content, that is
            due, in whole or in part, directly or indirectly to an event or
            failure which is beyond our reasonable control including without
            limitation:
          </p>
          <p>
            (a) acts of God, nature, explosions, floods, fire or accident, war
            or threat of war, terrorism or threat of terrorism, sabotage, civil
            disturbance, epidemics, prohibitions or measures of any kind on the
            part of any governmental, judicial, parliamentary or local
            authority, import or export regulations or embargoes, or industrial
            actions or trade disputes (whether involving our employees or of
            third parties);
          </p>
          <p>
            (b) a failure or interruption in public or private telecommunication
            networks, communications channels or information systems;
          </p>
          <p>
            (c) acts or omissions of any Third-Party Service provider or any
            other party for whom we are not responsible;
          </p>
          <p>
            (d) delay, failure or interruption in, or unavailability of, Third
            Party Services; and
          </p>
          <p>
            (e) viruses, other malicious computer codes or the hacking by any
            party of us or Third-Party Services.
          </p>
          <p>
            27.7 We may assign, sub-license and/or transfer our rights and
            obligations under these Terms to another party without notice to
            you. This includes (but is not limited to) sub-contracting delivery
            of a Service to any Group Company who operates the business relating
            to the relevant information, publication or data product forming
            part of that Service. You may not transfer, assign, sub-license
            and/or transfer any of your rights or obligations under these Terms
            to another party, save with our prior written consent.
          </p>
          <p>
            27.8 Failure or neglect by us to enforce at any time any of the
            provisions in these Terms shall not be construed or deemed to be a
            waiver of our rights hereunder, nor in any way affect the validity
            of the whole or any part of these Terms or prejudice our right to
            take subsequent action.
          </p>
        </>
      ),
      id: 'header26'
    },
    {
      header: '28. Additional Terms',
      desc: (
        <>
          <p>
            28.1 The following terms and policies are hereby incorporated into
            these Terms by reference and provide additional terms and conditions
            related to specific aspects or features of your use of the Sites
            and/or Services:
          </p>
          <p>
            Privacy Policy in relation to use of your personal data and/or
            information;
          </p>
          <p>
            28.2 You acknowledge and agree that you have read and understand the
            Terms and all the above terms and policies and agree to be bound
            thereby.
          </p>
        </>
      ),
      id: 'headeer27'
    }
  ]
  return (
    <section className='main'>
      <section className='terms-sec'>
        <Container>
          <Row>
            <TermsServices title={'Terms and Conditions of Services'} />
          </Row>

          <Row>
            <section className='terms-content'>
              <Accordion defaultActiveKey='0' flush>
                {dropDown?.map((item, index) => {
                  return (
                    <>
                      <div id={`header${index}`}></div>
                      <Accordion.Item
                        onClick={() => {
                          setCurrentIndex(`#header${index - 1}`)
                        }}
                        eventKey={index.toString()}
                      >
                        <TermsCollapse
                          Header={item?.header}
                          Details={item?.desc}
                          id={`header${index - 1}`}
                        />
                      </Accordion.Item>
                    </>
                  )
                })}
              </Accordion>
            </section>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Termcondiitons
