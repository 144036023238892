import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Demobanner from '../components/demobanner/Demobanner'
import Categories from '../components/blog-post/categories-search'

import Noresult from '../images/blogs/NoResultIcon.svg'

const Blogblank = ({ searchMediaBlog, query }) => {
  return (
    <section className='main'>
      <section className='blog-post'>
        <Container>
          <Row>
            <Col
              md={9}
              xl={10}
              className='order-last order-md-first text-center'
            >
              <div className='nofound-img'>
                <img src={Noresult} />
                <div className='nofound-heading'>No Result Found</div>
              </div>
            </Col>
            <Col
              md={3}
              xl={2}
              className='order-firt order-md-last mb-4 mb-md-0'
            >
              <Categories searchMediaBlog={searchMediaBlog} query={query} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Demobanner /> */}
    </section>
  )
}

export default Blogblank
