import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import React, { useState } from 'react'

import './about.css'
import Atilla from '../../images/about/atillaWidnell.png'
import Lee from '../../images/about/yiman.png'
import ANdreasPic from '../../images/about/andreas-pic.png'
import LinkdIn_ic from '../../images/about/linkdIn_ic.svg'
import Modal_atilla from '../../images/about/modal_atilla.png'
import { useParams } from 'react-router-dom'
const Leadership = () => {
  const leadershipdata = [
    {
      name: 'Atilla Widnell',
      post: 'Managing Director, Singapore',
      img: Atilla,
      desc: 'Prior to founding Navigate Commodities, Atilla has held executive and senior management positions at tier-1 commodity market intelligence agencies over the past 12 years, including CRU, Metal Bulletin Research (FastMarkets) and Mysteel Global.',
      ll: 'https://www.linkedin.com/in/atillawidnell/'
    },
    {
      name: 'Bai Yiman',
      img: Lee,
      post: 'Director, Singapore',
      desc: 'Yiman has almost a decade’s worth of experience in the European & Asian asset management & banking sectors, working with Lloyds Banking Group, UOB, and St James’s Place. She manages special projects and is the linchpin between our Shanghai & Singapore operations.',
      ll: 'https://www.linkedin.com/in/yimanbai'
    },
    {
      name: 'Andreas Tsindos',
      img: ANdreasPic,
      post: 'CTO, Dubai',
      desc: 'Andreas a Fintech, Digital and Blockchain professional with over 27 years experience, is bringing to life the Navigate vision via our Innovative Technology platform. He has been successfully running MWAN for the last 14 years with notable clients like UAE Telecoms Regulator, Del Monte, Vodafone. Has architected a Blockchain Enterprise Infrastructure Enablers delivering real Blockchain technology in Physical Commodities, Trade finance, Supply chain, Legal Contracts and Metal Certification.',
      ll: 'https://www.linkedin.com/in/andreas-tsindos-1174421/'
    }
  ]
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [currentdetails, setCurrentDetails] = useState(leadershipdata[0])
  const handleShow = index => {
    setCurrentDetails(leadershipdata[index])
    setShow(true)
  }
  const { id } = useParams()
  React.useEffect(() => {
    console.log(id)
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' })
    }
  }, [id])

  return (
    <>
      <section className='Leadership' id='leadership'>
        <Container>
          <h2 className='text-center'>Our Leadership</h2>
          <Row>
            {leadershipdata?.map((item, i) => {
              return (
                <Col md={4}>
                  <div
                    className='leadership-card'
                    onClick={() => {
                      handleShow(i)
                    }}
                  >
                    <div className='member-image'>
                      <img src={item?.img} alt='leadership-img' />
                    </div>
                    <div className='member-details'>
                      <div className='member-name'>{item?.name}</div>
                      <div className='member-post'>{item?.post}</div>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
        <Modal
          size='lg'
          show={show}
          onHide={handleClose}
          className='team_modal'
          centered
        >
          <Modal.Body>
            <Row>
              <Col md={6} lg={5}>
                <div className='user-img'>
                  <img src={currentdetails?.img} alt='leadership-img' />
                </div>
              </Col>
              <Col md={6} lg={7}>
                <div className='user-name'>{currentdetails?.name}</div>
                <div className='user-posiiton'>{currentdetails?.post}</div>
                <div className='user-details'>{currentdetails?.desc}</div>
                <div className='user_scial_links'>
                  <a href={currentdetails?.ll} target='_blank'>
                    <img src={LinkdIn_ic} alt='social-icon' />
                  </a>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default Leadership
