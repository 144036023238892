import { LinkedinShareButton, TwitterShareButton } from 'react-share'
const Blogshare = ({ LinkedIn, Instagram, twitter }) => {
  return (
    <div className='share-list'>
      <h2>Share</h2>
      <ul className='nav'>
        <li className='nav-item'>
          <LinkedinShareButton url={window?.location}>
            <a href='#'>
              <img src={LinkedIn} alt='social-iocn' />
            </a>
          </LinkedinShareButton>
        </li>
        {/* <li className='nav-item'>
          <a href='#'>
            <img src={Instagram} alt='social-iocn' />
          </a>
        </li> */}
        <li className='nav-item'>
          <TwitterShareButton url={window?.location}>
            <a href='#'>
              <img src={twitter} alt='social-iocn' />
            </a>
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  )
}

export default Blogshare
