import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./about.css";

import React from "react";

import Clock_ic from "../../images/about/bx-time-five.svg";
import Placeholderimg from "../../images/blogs/placeholderimg.jpg";

import Marquee from "react-fast-marquee";

import axios from "../../configuration/axios";
import { Link } from "react-router-dom";
import { utilities } from "../utlilities/utils";

const MediaCoverage = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [mediaCoverage, setMediaCoverage] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    getMediaCoverage();
  }, []);

  const getMediaCoverage = async () => {
    try {
      const response = await axios.get("website/media-coverages?page=1");
      if (response?.data) {
        setMediaCoverage(response.data);
      }
    } catch (err) {
      setError(err?.message);
    }
  };
  // debugger
  console.log("mediaResponse", mediaCoverage);

  var settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 0,
    // speed: 2500,
    // cssEase:'linear',
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    nav: true,
    pauseOnHover: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          nav: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          nav: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 3,
          nav: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          nav: false,
          dots: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          nav: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <section className="media-coverage">
      <Container fluid>
        <h2 className="text-center">Media Coverage</h2>
        <Row>
          <Col sm={12}>
            {/* <Slider {...settings}> */}

            <Marquee
              pauseOnHover={true}
              speed={window.innerWidth < 500 ? 10 : 30}
              gradient={window.innerWidth < 500 ? false : true}
            >
              {mediaCoverage?.data?.slice(0, 8)?.map((item) => {
                return item?.video_url !== null ? (
                  <a
                    href={item?.video_url}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="media-img">
                      <div className="Media-partner" key={item?.id}>
                        <div className="m-body">
                          <img
                            src={
                              item?.image !== null &&
                              item?.image !== undefined &&
                              item?.image.includes("https")
                                ? item?.image
                                : item?.image == null
                                ? Placeholderimg
                                : `${imageUrl}${item?.image}`
                            }
                            width="100%"
                            height="180px"
                            alt="media_partner"
                          />
                        </div>
                        <div className="m-footer">
                          <div className="f-header">
                            {JSON.stringify(item?.title)?.slice(1, 49)}...
                          </div>
                          <div className="f-content">
                            <img src={Clock_ic} alt="clock-icon" />
                            {utilities?.convertDateToLocal(item?.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link
                    to={`/media-details/${item?.id}/${item?.title?.replaceAll(
                      " ",
                      "-"
                    )}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="Media-partner" key={item?.id}>
                      <div className="m-body">
                        <img
                          src={
                            item?.image !== null &&
                            item?.image !== undefined &&
                            item?.image.includes("https")
                              ? item?.image
                              : item?.image == null
                              ? Placeholderimg
                              : `${imageUrl}${item?.image}`
                          }
                          width="100%"
                          height="180px"
                          alt="media_partner"
                        />
                      </div>
                      <div className="m-footer">
                        <div className="f-header">
                          {JSON.stringify(item?.title)?.slice(1, 49)}...
                        </div>
                        <div className="f-content">
                          <img src={Clock_ic} alt="clock-icon" />
                          {utilities?.convertDateToLocal(item?.date)}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Marquee>
            {/* </Slider> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MediaCoverage;
