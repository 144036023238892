
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


    const Services= ({image,header,pra})=>{
    return  (
            <Row className="align-items-center mb-3 mb-lg-5">
                <Col md = {6} xxl = {7} className = "mb-3 mb-md-0">
                    <h2>{header}</h2>
                    <p>
                        {pra}
                    </p>
                </Col>
                <Col md = {6} xxl = {5} className = "mb-3 mb-md-0">
                    <div className="sec-img">
                        <img src = {image} alt = "supply-demand"/>
                    </div>
                </Col>
            </Row>
        );
    }

export default Services