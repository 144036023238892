import axios from '../../configuration/axios'
export const mediaApi = {
  getBlogMedia: (url, cb) => {
    axios
      .get(url, { })
      .then(data => {
        cb(null, data)
      })
      .catch(err => {
        cb(err, null)
      })
  },
  getBlogMediaById: (url, data, cb) => {
    axios
      .post(url, data, { })
      .then(data => {
        cb(null, data)
      })
      .catch(err => {
        cb(err, null)
      })
  },
}
