// import { NavLink, Link } from "react-router-dom";
import React from 'react'
import Requestdemo from '../components/request-demo/Requestdemo'

const RequestDemo = () => {
  React.useEffect(() => {
    window.scrollTo({top:0,left:0,behavior:'instant'})
  }, [])

  return (
    <section className='main'>
      <Requestdemo />
    </section>
  )
}

export default RequestDemo
