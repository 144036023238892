import BlogMedia from '../components/blog-media/index'
import { useEffect } from 'react'
const Insitemedia = () => {
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo({top:0,left:0, behavior:'instant'})
      
    }, 240);
  }, [])
  return <BlogMedia type='media' />
}

export default Insitemedia
